<template>
  <div class="">
    <div class="body">
      <div class="container">
        <p> <span class="back" @click="returnArticles"> &lt;&lt; 返回资讯列表</span></p>
        <h1>{{article.title}}</h1>
        <h4>{{article.createTime}}</h4>
        <article ref="content">
          <span v-html="html"></span>
        </article>
      </div>
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
import { getNewsDetail } from "@api"
import Vditor from 'vditor'
import { ref } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
export default {
  name: "article",
  setup () {
    const route = useRoute()
    const router = useRouter()
    const id = ref(route.params.id)
    const html = ref('')
    const article = ref({})
    const content = ref(null)
    const returnArticles = () => {
      router.replace('/articles')
    }

    getNewsDetail(id.value).then(res => {
      article.value = res.data
      if (res.data.records) {
        Vditor.preview(
          content.value,
          res.data.records
        )
      }
    })

    return {
      id,
      html,
      article,
      content,
      returnArticles
    }
  }
};
</script>


<style scoped lang="stylus">
@import url('../../node_modules/vditor/dist/index.css');

.body {
  height: 100%;
  background: #fff;
}

.container {
  padding: 20px 0;
}

h1, h4 {
  text-align: center;
}

h4 {
  color: #999;
}

.back {
  cursor: pointer;
  color: #aaa;
  margin-top: 10px;
  display: inline-block;
}
</style>
